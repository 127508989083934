import { Fragment } from "react";
import Sidebar from "../sections/sidebar";

const Dashboard = () => {
    return (  
        <div class="lg_s content">
            <div class="container d-flex">
                <div class="chart">
                    <div class="chart_head">
                        <h4>Chart Title</h4>
                    </div>
                    <hr />
                    <div class="chart_body">
                        <img src="http://surl.li/gjpoj" alt="" width="100%" />
                    </div>
                    <div class="chart_footer">
                        <p>This Is Test Chart</p>
                    </div>
                </div>
                <div class="chart">
                    <div class="chart_head">
                        <h4>Chart Title</h4>
                    </div>
                    <hr />
                    <div class="chart_body">
                        <img src="http://surl.li/gjpoj" alt="" width="100%" />
                    </div>
                    <div class="chart_footer">
                        <p>This Is Test Chart</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;