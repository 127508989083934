import { ChatRightText, InfoSquare, Receipt, Speedometer, Speedometer2, WrenchAdjustableCircle, People, CurrencyEuro } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
    return (        
        <div class="d-none d-lg-block desk_nav sidebar">
            <ul id="nav">
                <li>
                    <NavLink to="/dashboard" activeClassName='active' >
                        <Speedometer2 className="me-2" />Dashboard
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/quotation" activeclassname='active' >
                        <WrenchAdjustableCircle className="me-2" />Quotation Tool
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/casesquoted" activeclassname='active' >
                        <Receipt className="me-2" />Cases Quoted
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/casesopened" activeclassname='active' >
                        <Receipt className="me-2" />Cases Opened
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/casesaborted" activeclassname='active' >
                        <Receipt className="me-2" />Aborted Cases
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/otherservices" activeclassname='active' >
                        <InfoSquare className="me-2" />Other Services
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/message" activeclassname='active' >
                        <ChatRightText className="me-2" />Message
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/users" activeclassname='active' >
                        <People className="me-2" />Users
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/prices" activeclassname='active' >
                        <CurrencyEuro className="me-2" />Prices
                    </NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Sidebar;