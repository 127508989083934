import { Fragment } from "react";
import { signOut } from "firebase/auth";
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom'
import { NavLink } from "react-router-dom";
import { BoxArrowLeft, ChatFill, Gear, GearFill, Person, PersonCircle, PersonFill } from "react-bootstrap-icons";
import { Dropdown, DropdownButton } from "react-bootstrap";
const Header = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate("/login");
            console.log("Signed out successfully")
        }).catch((error) => {
        });
    }
    return (
        <Fragment>
            <header>
                <div class="align-items-center d-flex justify-content-between bg-1 p-3">
                    <div class="d-center">
                        <a class="btn-bar" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                            <i class="bi bi-list h3 col_1"></i>
                        </a>
                        <a href="/">
                            <img src="./images/dash_logo.webp" alt="wilford-smith" width="130px" />

                            </a>
                    </div>
                    <div class="align-items-center d-flex justify-content-between gap">
                        <ChatFill className="col_1 bi bi-chat-fill h5" />
                        <GearFill className="col_1 h5" />

                        <div>
                        <Dropdown className="profile_dropdown">
                            <Dropdown.Toggle className="btn-2" id="dropdownMenuButton1">
                                <PersonCircle className="h5" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div class="profile p-2 px-3 bg-1 mb-2 d-flex">
                                    <div class="profile_img cen-1">
                                        <PersonCircle />
                                    </div>
                                    <div class="profile_text cen-1 mx-2">
                                        Abhishek Khanna
                                    </div>
                                </div>
                                <Dropdown.Item href="#/action-2"><PersonFill className="me-2" />Profile</Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><Gear className="me-2" /> Settings</Dropdown.Item>
                                    <div class="p-2 px-3 flex mt-2 logout_btn" onClick={handleLogout}>
                                        <BoxArrowLeft className="me-2" /> Log Out
                                    </div>
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}

export default Header;